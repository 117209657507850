<template>
    <el-scrollbar class="teacher-page">
        <div class="t-page">
            <div class="t-page-title">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ name: 'teacherMarketIndex' }">{{ $t('teacher.breadcrumb.contentManege') }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ $t('teacher.breadcrumb.details') }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="t-page-wrap">
                <el-form ref="postForm" :model="postForm" :rules="postRules" label-width="200px" size="small">
                    <el-form-item :label="$t('teacher.tableHead.activityName')" prop="name">
                        <el-input v-model="postForm.name" class="w400" maxlength="21" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.tagName')" prop="activity_label_id">
                        <el-select v-model="postForm.activity_label_id" style="margin-right: 10px;">
                            <el-option v-for="item in tagsOptions" :key="item.activity_label_id"
                                :label="item.label_name" :value="item.activity_label_id" />
                        </el-select>
                        <el-button type="danger" @click="$router.push({name:'teacherMarketTags'})">{{ $t('teacher.actions.addTag') }}</el-button>
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.activityBg')" prop="img_url">
                        <div class="upload-container">
                            <el-upload v-loading="loading" :show-file-list="false" :http-request="(data) => { httpRequest(data.file) }"
                                accept="images/*" class="upload-action" action="#">
                                <i class="el-icon-plus" />
                                <div class="el-upload__text">
                                    <p>{{ $t('teacher.actions.uploadImage') }}</p>
                                </div>
                            </el-upload>
                            <div v-show="postForm.img_url" class="upload-preview">
                                <img :src="postForm.img_url" class="upload-preview-icon">
                                <div class="upload-preview-action">
                                    <i class="el-icon-delete" @click="handleRemove" />
                                </div>
                            </div>
                        </div>
                        <div>{{ $t('teacher.tips.imageSize1') }}</div>
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.activityPrimary')">
                        <el-color-picker v-model="postForm.bg_color" show-alpha />
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.shopEnrollTime')" prop="s_enroll_end_time">
                        <el-date-picker v-model="shopEnrollTimes" type="datetimerange" :range-separator="$t('teacher.unit.to')"
                            :start-placeholder="$t('teacher.placeholder.startTime')" :end-placeholder="$t('teacher.placeholder.endTime')" value-format="timestamp"
                            :picker-options="pickerShopOptions" :default-time="['00:00:00', '23:59:59']"
                            @change="handleChangeShopTimes" />
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.goodsEnrollTime')" prop="g_enroll_end_time">
                        <el-date-picker v-model="goodsEnrollTimes" type="datetimerange" :range-separator="$t('teacher.unit.to')"
                            :start-placeholder="$t('teacher.placeholder.startTime')" :end-placeholder="$t('teacher.placeholder.endTime')" value-format="timestamp"
                            :picker-options="pickerGoodsOptions" :default-time="['00:00:00', '23:59:59']"
                            @change="handleChangeGoodsTimes" />
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.preheatTime')" prop="warm_up_end_time">
                        <el-date-picker v-model="preheatTimes" type="datetimerange" :range-separator="$t('teacher.unit.to')"
                            :start-placeholder="$t('teacher.placeholder.startTime')" :end-placeholder="$t('teacher.placeholder.endTime')" value-format="timestamp"
                            :picker-options="pickerPreheatOptions" :default-time="['00:00:00', '23:59:59']"
                            @change="handleChangePreheatTimes" />
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.salesTime')" prop="sales_end_time">
                        <el-date-picker v-model="salesTimes" type="datetimerange" :range-separator="$t('teacher.unit.to')"
                            :start-placeholder="$t('teacher.placeholder.startTime')" :end-placeholder="$t('teacher.placeholder.endTime')" value-format="timestamp"
                            :picker-options="pickerSalesOptions" :default-time="['00:00:00', '23:59:59']"
                            @change="handleChangeSalesTimes" />
                    </el-form-item>
                    <el-form-item :label="$t('teacher.formItem.activityIntro')" prop="content">
                        <Tinymce ref="editor" v-model="postForm.content" :height="400" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit">{{ $t('teacher.actions.submit') }}</el-button>
                        <el-button @click="handleCancel">{{ $t('teacher.actions.cancel1') }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import { OSSUpload } from '@/utils/upload'
import { fetchMarketInfo, createMarket, updateMarket, fetchMarketTags } from '@/api/teacher'
export default {
    components: { Tinymce },
    data() {
        const that = this
        const validateRequire = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(that.$t('teacher.validate.required')))
            } else {
                callback()
            }
        }
        const validateRequireGoods = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(that.$t('teacher.validate.required')))
            } else if (that.shopEnrollTimes === '') {
                that.$refs.postForm.validateField('s_enroll_end_time')
            } else if (that.goodsEnrollTimes[1] < that.shopEnrollTimes[1]) {
                callback(new Error(that.$t('teacher.validate.goodsEnrollTime')))
            } else {
                callback()
            }
        }
        
        return {
            tagsOptions: [],
            shopEnrollTimes: '',
            pickerShopOptions: {
                disabledDate(time) {
                    var startTime = Date.now() - 8.64e7
                    if (that.goodsEnrollTimes) {
                        return time.getTime() < startTime || time.getTime() > (that.goodsEnrollTimes[1])
                    } else {
                        return time.getTime() < startTime
                    }
                }
            },
            goodsEnrollTimes: '',
            pickerGoodsOptions: {
                disabledDate(time) {
                    var startTime = Date.now() - 8.64e7
                    if (that.shopEnrollTimes) {
                        startTime = that.shopEnrollTimes[0]
                    }
                    if (that.preheatTimes) {
                        return time.getTime() < startTime || time.getTime() > (that.preheatTimes[0] - 1)
                    } else {
                        return time.getTime() < startTime
                    }
                }
            },
            preheatTimes: '',
            pickerPreheatOptions: {
                disabledDate(time) {
                    var startTime = Date.now() - 8.64e7
                    if (that.goodsEnrollTimes) {
                        startTime = that.goodsEnrollTimes[1]
                    }
                    if (that.salesTimes) {
                        return time.getTime() < startTime || time.getTime() > (that.salesTimes[0] - 1)
                    } else {
                        return time.getTime() < startTime
                    }
                }
            },
            salesTimes: '',
            pickerSalesOptions: {
                disabledDate(time) {
                    var startTime = Date.now() - 8.64e7
                    if (that.preheatTimes) {
                        startTime = that.preheatTimes[1]
                    }
                    return time.getTime() < startTime
                }
            },
            postForm: {
                name: '',
                activity_label_id: '',
                content: '',
                describe: '',
                img_url: '',
                s_enroll_start_time: '',
                s_enroll_end_time: '',
                g_enroll_start_time: '',
                g_enroll_end_time: '',
                warm_up_start_time: '',
                warm_up_end_time: '',
                sales_start_time: '',
                sales_end_time: '',
                bg_color: ''
            },
            postRules: {
                name: [{ required: true, validator: validateRequire }],
                activity_label_id: [{ required: true, validator: validateRequire }],
                content: [{ required: true, validator: validateRequire }],
                img_url: [{ required: true, validator: validateRequire }],
                s_enroll_end_time: [{ required: true, validator: validateRequire }],
                g_enroll_end_time: [{ required: true, validator: validateRequireGoods }],
                warm_up_end_time: [{ required: true, validator: validateRequire }],
                sales_end_time: [{ required: true, validator: validateRequire }]
            },
            loading: false
        }
    },
    watch: {
        'postForm.content': {
            handler: function (val, oldVal) {
                if (val) {
                    this.$refs.postForm.validateField('content')
                }
            },
            deep: true
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                const tagsData = await fetchMarketTags()
                this.tagsOptions = tagsData.data.list
            } catch (error) {
                console.log(error)
            }

            if (this.$route.params.id > 0) {
                this.fetchData()
            }
        },
        fetchData() {
            fetchMarketInfo({ activity_id: this.$route.params.id }).then(response => {
                const { data } = response
                this.postForm = data
                this.shopEnrollTimes = [data.s_enroll_start_time * 1000, data.s_enroll_end_time * 1000]
                this.goodsEnrollTimes = [data.g_enroll_start_time * 1000, data.g_enroll_end_time * 1000]
                this.preheatTimes = [data.warm_up_start_time * 1000, data.warm_up_end_time * 1000]
                this.salesTimes = [data.sales_start_time * 1000, data.sales_end_time * 1000]
                this.$nextTick(() => {
                    this.$refs.postForm.clearValidate()
                })
            }).catch(error => {
                console.log(error)
                this.$router.go(-1)
            })
        },
        async httpRequest(file) {
            const isType = ['image/jpeg', 'image/png'].includes(file.type)
            const isSize = file.size / 1024 / 1024 < 3
            if (!isType) {
                this.$message.error(this.$t('teacher.errorMsg.imageType'))
                return false
            }
            if (!isSize) {
                this.$message.error(this.$t('teacher.errorMsg.imageSize3M'))
                return false
            }
            this.loading = true
            const { url } = await OSSUpload(file, 'market')
            if (url) {
                this.postForm.img_url = url
            } else {
                this.$message.error(this.$t('teacher.errorMsg.upload'))
            }
            this.$refs.postForm.validateField('img_url')
            this.loading = false
        },
        handleRemove() {
            this.postForm.img_url = ''
        },
        handleChangeShopTimes() {
            if (this.shopEnrollTimes) {
                this.postForm.s_enroll_start_time = parseInt(this.shopEnrollTimes[0] / 1000)
                this.postForm.s_enroll_end_time = parseInt(this.shopEnrollTimes[1] / 1000)
            }
        },
        handleChangeGoodsTimes() {
            if (this.goodsEnrollTimes) {
                this.postForm.g_enroll_start_time = parseInt(this.goodsEnrollTimes[0] / 1000)
                this.postForm.g_enroll_end_time = parseInt(this.goodsEnrollTimes[1] / 1000)
            }
        },
        handleChangePreheatTimes() {
            if (this.preheatTimes) {
                this.postForm.warm_up_start_time = parseInt(this.preheatTimes[0] / 1000)
                this.postForm.warm_up_end_time = parseInt(this.preheatTimes[1] / 1000)
            }
        },
        handleChangeSalesTimes() {
            if (this.salesTimes) {
                this.postForm.sales_start_time = parseInt(this.salesTimes[0] / 1000)
                this.postForm.sales_end_time = parseInt(this.salesTimes[1] / 1000)
            }
        },
        handleSubmit() {
            this.$refs.postForm.validate(async valid => {
                if (valid) {
                    this.loading = true
                    const params = { ...this.postForm }
                    try {
                        if (this.$route.params.id > 0) {
                            await updateMarket(params)
                            this.$message.success(this.$t('teacher.successMsg.update'))
                        } else {
                            await createMarket(params)
                            this.$message.success(this.$t('teacher.successMsg.add'))
                        }
                        this.$router.push({ name: 'teacherMarketIndex' })
                    } catch (error) {
                        console.log(error)
                    }
                    this.loading = false
                } else {
                    return false
                }
            })
        },
        handleCancel() {
            this.$router.push({ name: 'teacherMarketIndex' })
        },
		formatDate(val) {
			if (val > 0) {
				let date = new Date(val);
				let y = date.getFullYear();
				let m = (date.getMonth() + 1).toString().padStart(2, '0');
				let d = date.getDate().toString().padStart(2, '0');
				return y + '-' + m + '-' + d;
			}
			return '';
		},
    }
}
</script>

<style lang="scss" scoped>
.t-page {
    padding: 20px;

    .t-page-title {
        padding-bottom: 20px;
        margin-bottom: 20px;

        .title {
            line-height: 20px;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .t-page-wrap {
        max-width: 1200px;

        .w400 {
            width: 400px;
        }
    }
}

.upload-container {
    width: 120px;
    height: 120px;
    position: relative;

    .upload-action {
        height: 100%;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;

        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
        }

        &:hover {
            border-color: #409EFF;
        }

        .el-upload__text {
            line-height: 24px;

            p {
                margin: 0;
            }
        }
    }

    .el-icon-plus {
        font-size: 24px;
    }

    .upload-preview {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        border: 1px dashed #d9d9d9;
        background-color: #FFFFFF;
        position: absolute;

        .upload-preview-icon {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .upload-preview-action {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            cursor: default;
            text-align: center;
            color: #fff;
            opacity: 0;
            font-size: 24px;
            background-color: rgba(0, 0, 0, .5);
            transition: opacity .3s;
            cursor: pointer;
            text-align: center;
            line-height: 120px;
        }

        &:hover {
            .upload-preview-action {
                opacity: 1;
            }
        }
    }
}
</style>